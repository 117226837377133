import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/javascript-arrays",
  "date": "2016-01-29",
  "title": "JAVASCRIPT ARRAYS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "This beginner-level article will discuss further about JavaScript arrays in detail. JavaScript arrays are used to store multiple values in a single variable. JavaScript has an built-in object for manipulating arrays called Array."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="info" title="Array" content="The JavaScript Array object is a global object that is used in the construction of arrays; which are high-level, list-like objects. – **MDN**" mdxType="Message" />
    <p>{`Let's look at some useful helper functions JavaScript Array provides to us.`}</p>
    <h2>{`Concat`}</h2>
    <p>{`Concat creates a shallow copy of an existing array that includes any arguments you pass to it.
Let's look at using concat for adding additional values to an array then cover some more useful features such as accepting other arrays as arguments & how to chain concat with other array methods such as forEach.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var cars = [
  {
    brand: 'Ford'
  },
  {
    brand: 'Cadillac'
  }
];

var trucks = [
  {
    brand: 'Pontiac'
  },
  {
    brand: 'Chevrolet'
  },
  {
    brand: 'Buick'
  },
];

vehicles = cars.concat(trucks);

vehicles.forEach(function (vehicle) {
  console.log(vehicle.brand);
});
`}</code></pre>
    <p>{`Above code will print out the following on the web console:`}</p>
    <pre><code parentName="pre" {...{}}>{`"Ford"
"Cadillac"
"Pontiac"
"Chevrolet"
"Buick"
`}</code></pre>
    <p>{`Concat is a method that you call on an existing array and it returns back to you a new array that has the original, plus any parameters you pass in here.`}</p>
    <h2>{`Join`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`join()`}</inlineCode>{` method joins all elements of an array into a string. First, let's look at why join is often a better option than regular string concatenation.`}</p>
    <p>{`If we wanted to concatenate, first name and last name, we have to output this full name with a space in-between without using join, you would have to do something like access the first item in the array, add an empty space, access the second item, and that would work. This is extremely fragile, however. Just adding a middle name can break it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var name = 'John Doe';

var names = name.split(' ');

console.log(names[0] + ' ' + names[1]);
`}</code></pre>
    <p>{`What you really want here is to say however many items there are, return all of them back as a string, and I want to provide the separator or the glue in-between. That's how join works. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var name = 'john doe';

var namesArray = name.split(' ');

var names = namesArray.map(function(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  });

names = names.join(' ');

console.log(names);
`}</code></pre>
    <p>{`Above code will print out the following on the web console:`}</p>
    <pre><code parentName="pre" {...{}}>{`"John Doe"
`}</code></pre>
    <h2>{`Filter`}</h2>
    <p>{`Array filter creates a new array with all elements that pass the test implemented by the provided function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var vehicles = [
    {
        brand: 'Peugeot',
        country: 'France'
    },
    {
        brand: 'BMW',
        country: 'Germany'
    },
    {
        brand: 'Audi',
        country: 'Germany'
    }
];

var list = vehicles
    .filter(function(vehicle) {
      return vehicle.country === 'Germany';
    })
    .map(function(vehicle) {
      return vehicle.brand + ' - ' +vehicle.country;
    })
    .join('\\n');


console.log(list);
`}</code></pre>
    <p>{`The result on web console will be:`}</p>
    <pre><code parentName="pre" {...{}}>{`"BMW - Germany
Audi - Germany"
`}</code></pre>
    <p>{`Array filter creates a new array. It does this by calling a function that you provide, as the first argument with each item in the source array. Then it looks at the return value from that function call.`}</p>
    <p>{`If it's a truthy value, such as a number, a string, or true, then the current item we're looking at will make it into the filtered array. However, if the function call returns a falsy value, then the item is discarded.`}</p>
    <h2>{`Some`}</h2>
    <p>{`The method `}<inlineCode parentName="p">{`some`}</inlineCode>{` returns a boolean value after passing each item in the source array through the test function that you pass in as the first parameter. This makes it well suited to the types of queries that require a simple yes or no answer.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var vehicles = [
    {
        brand: 'Peugeot',
        country: 'France'
    },
    {
        brand: 'BMW',
        country: 'Germany'
    },
    {
        brand: 'Audi',
        country: 'Germany'
    }
];

var isMadeInFrance = vehicles
    .some(function(vehicle) {
      return vehicle.country === 'France';
    });

console.log(isMadeInFrance);
`}</code></pre>
    <p>{`Array Some accepts a function as its first parameter. It will begin to call this function once for every item in this source array. Should any of those function calls to yield a truthy value, then Some will return true.`}</p>
    <h2>{`IndexOf`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`indexOf`}</inlineCode>{` function is used to search for a value or reference inside of an array. If the value was not found, -1 will be returned as the result. If the value was found, an integer larger than -1 will be returned. (eg:- 0, 1, 2 etc)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var filename = 'style.css';

var hasExtension = filename.indexOf('.c');
console.log(hasExtension);

hasExtension = filename.indexOf('z');
console.log(hasExtension);
`}</code></pre>
    <p>{`Above code will print out the following on the web console:`}</p>
    <pre><code parentName="pre" {...{}}>{`  5
-1
`}</code></pre>
    <p>{`The following example will show how to filter an object by looking into it's extension using `}<inlineCode parentName="p">{`filter`}</inlineCode>{` and `}<inlineCode parentName="p">{`indexOf`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var list = ['.css', '.js'];

var events = [
  {
    file: 'css/core.css'
  },
  {
    file: 'js/app.js'
  },
  {
    file: 'index.html'
  }
];

var filtered = events.filter(function(event) {
  var ext = event.file.substr(event.file.lastIndexOf('.'));
  return list.indexOf(ext) > -1;
});

console.log(filtered);
`}</code></pre>
    <p>{`Above code will print out the following on the web console:`}</p>
    <pre><code parentName="pre" {...{}}>{`  [[object Object] {
  file: "css/core.css"
}, [object Object] {
  file: "js/app.js"
}]
`}</code></pre>
    <h2>{`Slice`}</h2>
    <p>{`Array slice creates a shallow copy of an array. This method extracts items from an array starting from zero-based index.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var cars = ['Buick', 'Renault', 'Toyota'];


console.log(
  cars.slice(1), // / slice(start)
  cars.slice(1, cars.length-1) // slice(start, end)
);
`}</code></pre>
    <p>{`Now let's look at the web console:`}</p>
    <pre><code parentName="pre" {...{}}>{`  ["Renault", "Toyota"]
  ["Renault"]
`}</code></pre>
    <h2>{`Sort`}</h2>
    <p>{`Sort can automatically arrange items in an array. It sorts the elements in an array in place and returns the same array.`}</p>
    <p>{`Let's look at how to sort an array of strings alphabetically and the correct way to perform a numerical sort on an array of numbers.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var vehicles = [
    {
        brand: 'Peugeot',
        country: 'France'
    },
    {
        brand: 'BMW',
        country: 'Germany'
    },
    {
        brand: 'Volvo',
        country: 'Sweden'
    }
];

var list = vehicles
    .sort(function(a, b) {
      return b.country - a.country;
    })
    .map(function(vehicle) {
      return vehicle.brand + ' - ' +vehicle.country;
    })
    .join('\\n');


console.log(list);
`}</code></pre>
    <p>{`The output on the web console will look like the following:`}</p>
    <pre><code parentName="pre" {...{}}>{`Peugeot - France
BMW - Germany
Volvo - Sweden
`}</code></pre>
    <p>{`The reason why something so simple as this works for a numeric sort, is that this comparative function only cares that you return either 0, or a value less than 0, or a value above 0. For example, the longhand version of this might look something like, If A - B = 0, return 0. If A - B < 0, return -1. If it's more than 0, return 1. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      